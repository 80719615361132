// blog data
export const Blogs = [
    {
        id:1,
        name:"Top 5 websites where obtain DataSets for your Data Science projects",
        tags:["Data Science","Data","Machine Learning","Data Visualization","Future"],
        date:"3 Aug, 2022",
        imgSrc:"https://miro.medium.com/max/875/1*EmcM8EGaq7oFxfVpTwehNQ.jpeg",
        link:"https://medium.com/@DofData/top-5-websites-where-obtain-datasets-for-your-data-science-projects-a7b68e689bd0"
    },
    {
        id:2,
        name:"An Introduction to Data Preprocessing: Cleaning, Normalizing and Feature Engineering",
        tags:["Data Science","Data Cleaning","Data Engineering","PandasData"],
        date:"24 Jan, 2023",
        imgSrc:"https://miro.medium.com/max/828/0*0py6gjXTW8IDwSSb",
        link:"https://medium.com/@DofData/an-introduction-to-data-preprocessing-cleaning-normalizing-and-feature-engineering-d1186be0ffbe"
    },
    {
        id:3,
        name:"A Beginner’s Guide to Pandas for Data Manipulation",
        tags:["Data Science","Pandas","Data","Python","Science"],
        date:"30 Jan, 2023",
        imgSrc:"https://miro.medium.com/v2/resize:fit:640/format:webp/1*mDyQpUEBchdYQcWCDsSWOg.jpeg",
        link:"https://medium.com/@DofData/a-beginners-guide-to-pandas-for-data-manipulation-43be1a364204"
    },
    {
        id:4,
        name:"The Ultimate Guide to Markdown: How to Write and Format Text Like a Pro",
        tags:["Data Science","Documentation","Markdown","Internet Of Things","Writing"],
        date:"11 Feb, 2023",
        imgSrc:"https://miro.medium.com/v2/resize:fit:828/format:webp/1*a3GTvJbSiV_poN5Xqv2xxw.jpeg",
        link:"https://medium.com/@DofData/the-ultimate-guide-to-markdown-how-to-write-and-format-text-like-a-pro-e7ceb04e3812"
    },
    
] 