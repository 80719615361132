import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Me from '../assets/Images/profile-piccc.png';


const Box = styled(motion.div)`

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);


    width: 65vw;
    height: 55vh;
    display: flex;
    
    background: linear-gradient(
        to right,
        ${props => props.theme.body} 50%,
        ${props => props.theme.text} 50%) bottom,
        linear-gradient(
        to right,
        ${props => props.theme.body} 50%,
        ${props => props.theme.text} 50%) top;
        background-repeat: no-repeat;
        background-size: 100% 2px;
        border-left: 2px solid ${props => props.theme.body};
        border-right: 2px solid ${props => props.theme.text};
        
        z-index: 1;
`
const SubBox = styled.div`

width: 50%;
position: relative;
display: flex;
.pic{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0%);
    width: 75%;
    height: auto;
}
`
const Text = styled.div`
font-size: calc(1em + 1.5vw);
color: ${props => props.theme.body};
padding: 2rem;
curson: pointer;
font-family: Serif, Karla ,sans-serif ;
display: flex;
flex-direction: column;
justify-content: space-evenly;

&>*:last-child{
    color: ${props => `rgba(${props.theme.bodyRgba},0.6)` };
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 300;
}   
`
const Intro = () => {
    return (
        <Box
        initial={{height:0}}
        animate={{height: '55vh'}}
        transition={{ type:'spring', duration:2, delay:1 }}
        >
            <SubBox>
                <Text>
                    <h1>Hi!!</h1>
                    <h3>I'm David Silvera</h3>
                    <h6>The head of my family, CIO & Co-Founder at NEXTSYNAPSE | Data Science Tutor</h6>
                </Text>
            </SubBox>
            <SubBox>
                <motion.div
                    initial={{opacity:0}}
                    animate={{opacity: 1}}
                    transition={{ duration:0.5, delay:1 }}
                    >
                    <img className="pic" src={Me}  alt='Profile pic' />
                </motion.div>
            </SubBox>
           
        </Box>
    );
}
export default Intro;