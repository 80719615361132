import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {LightTheme} from './Themes';


import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
//import BigTitle from '../subComponents/BigTitlte'

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:auto;
position: relative;
display: flex;
justify-content: center;
align-items: center;
`
const Main = styled.div`
border: 2px solid ${props => props.theme.text};
margin-top: 4rem;
color: ${props => props.theme.text};
background-color: ${props => props.theme.body};
width: 60vw;
height:auto;
z-index:0;
line-height: 2;
font-family: Serif, Karla ,sans-serif;
display: flex;
flex-direction: column;
align-items: center;
` 
const Data = styled.div`
color: ${props => props.theme.text};
font-size: calc(0.5em + 0.3vw);
align-items: center;
justify-content: center;
`
const Summary = styled.div`
color: ${props => props.theme.text};
font-size: calc(0.7em + 0.3vw);
margin: 1rem;
`
const Description = styled.div`
color: ${props => props.theme.text};
font-size: calc(0.7em + 0.3vw);
margin: 1rem;
`
const SubTitle = styled.h2`
color: ${props => props.theme.text};
align-items: left;
justify-content: left;
`
const MiniTitle = styled.h3`
color: ${props => props.theme.text};
align-items: left;
justify-content: left;
`

const MySkillsPage = () => {
    return (
        <ThemeProvider theme={LightTheme}>
            <Box>

                <LogoComponent theme='light'/>
                <SocialIcons theme='light'/>
                {/*<ParticleComponent theme='light' />*/}
                <Main>
                    <h1>David Silvera Gabriel</h1>
                    <h3>CIO & Co-Founder at NEXTSYNAPSE | Data Scientist</h3>
                    <Data>
                        ingenieria.d.s.g@hotmail.com / (+54) 2657626313 
                    </Data>
                    
                    <SubTitle>Summary</SubTitle>
                    
                    <Summary>
                    Passionate and self-taught Data Scientist with a robust track record of over 3 years in the field. As a seasoned freelancer, I've
                    demonstrated expertise in diverse domains such as Neuroscience, contributing to the development of an innovative
                    end-to-end EEG classifier enabling mind-controlled robotics. Additionally, my proficiency extends to Computer Vision, where I
                    designed an application leveraging head and eye pose estimation for mouse control. In the field of Education, I have stood
                    out as a Data Science tutor, accompanying students in their learning
                    </Summary>
                    <SubTitle>Skills</SubTitle>
                    <Description>• Python • Numpy • Pandas • Matplotlib • SQL • Scikit-Learn • Machine learning • Data Science • Computer vision 
                    • Data Cleaning • Exploratory analysis • Data Visualization • Feature Engineering 
                    • Feature Selection • ML modeling • Validation and Testing </Description>
                    <SubTitle>Work Experience</SubTitle>
                    <MiniTitle>Chief Information Officer (CIO) at NEXTSYNAPSE</MiniTitle>
                    <Description>
                    In just 3 months, I led the end-to-end development of our inaugural prototype, successfully enabling mind-controlled
                    management of an Arduino UNO robot. Simultaneously, I play a pivotal role in supporting the CEO's fundraising efforts, aligning
                    technical innovation with strategic financial goals.
                    </Description>
                    <MiniTitle>Data Scientist Tutor at CoderHouse</MiniTitle>
                    <Description>
                    As the Education Facilitator for the Data Science course, I assume a pivotal role in monitoring and
                    guiding students through their learning journey. My responsibilities extend to providing robust
                    support, stepping in for the teacher when required, and meticulously evaluating practical
                    assignments and final pre-deliverables.
                    </Description>
                    <MiniTitle>Data Scientist Freelance at Fiverr</MiniTitle>
                    <Description>
                    As a dedicated Freelance Data Scientist on the Fiverr platform, I have consistently delivered successful
                    outcomes across all completed projects. My work spans diverse domains, showcasing expertise in data
                    science applications. Notably, my clientele has expressed full satisfaction with the delivered solutions,
                    reflected in consistently high ratings.
                    </Description>
                    
                    <SubTitle>Projects</SubTitle>
                    
                    <MiniTitle>EEG classifier</MiniTitle>
                    <Description>
                    I utilized mne and braindecode libraries to analyze and classify public EEG signals from physionet.org.
                    The study, inspired by Braindecode's research, centered on employing deep ConvNets to decode
                    imagined or executed movements from raw EEG data.
                    </Description>
                    <MiniTitle>Computer pointer controller</MiniTitle>
                    <Description>
                    Created a cutting-edge app for mouse control using head and eye pose estimation from input videos.
                    The application accurately estimates eye direction and head pose, enabling intuitive mouse pointer
                    movement.
                    </Description>
                    
                    <SubTitle>Education</SubTitle>
                    
                    <MiniTitle>Nanodegree: Intel® Edge AI for IoT Developers Nanodegree</MiniTitle>
                    <Description>
                    I've worked in the development of cutting-edge Edge AI applications for the future of the Internet of
                    Things. I've Used the Intel® Distribution of OpenVINO™ Toolkit to fast-track development of
                    high-performance computer vision & deep learning inference applications.
                    </Description>
                    <MiniTitle>Plan 111 mil Programadores</MiniTitle>
                    <Description>Villa Mercedes, Argentina
                    Trained to develop and interpret UML, with a solid foundation in Java programming and knowledge
                    advanced in the query language par excellence SQL
                    </Description>
                    
                    <SubTitle>Languages</SubTitle>
                    
                    * Spanish Native
                    * English Conversational 
                </Main>

            {/*<BigTitle text="Curriculum Vitae" top="70%" right="20%" />*/ }

            </Box>

        </ThemeProvider>
        
    )
}

export default MySkillsPage